<template>
  <div>
    <div class="awards">
      <h1>奖项设置 <span>（请按抽奖顺序添加）</span></h1>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          width="180"
          v-for="(item, index) in columns"
          :key="index"
        >
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.editing[item.prop]">
                <el-input
                  size="mini"
                  v-model="scope.row[item.prop]"
                  @blur="onSaveCell(scope.row, item.prop)"
                >
                </el-input>
              </div>
              <div v-else @dblclick="onEditCell(scope.row, item.prop, $event)">
                {{ scope.row[item.prop] || "(请填写)" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="onAdd(scope.row, scope.$index)"
              type="primary"
            >
              增加
            </el-button>
            <el-button
              size="small"
              @click="onRemove(scope.row, scope.$index)"
              type="danger"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-button
        size="small"
        type="primary"
        @click="onAdd(null, -1)"
        v-show="tableData.length === 0"
        >增加
      </el-button>
      <h1>设置奖池<span>(不设置默认填写0)</span></h1>
      <el-table :data="tableDataes" style="width: 100%">
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          width="180"
          v-for="(item, index) in tableses"
          :key="index"
        >
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.editing[item.prop]">
                <el-input
                  size="mini"
                  v-model="scope.row[item.prop]"
                  @blur="onSaveCell(scope.row, item.prop)"
                >
                </el-input>
              </div>
              <div v-else @dblclick="onEditCell(scope.row, item.prop, $event)">
                {{ scope.row[item.prop] || "(请填写)" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="onAdds(scope.row, scope.$index)"
              type="primary"
            >
              增加
            </el-button>
            <el-button
              size="small"
              @click="onRemoves(scope.row, scope.$index)"
              type="danger"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        size="small"
        type="primary"
        @click="onAdds(null, -1)"
        v-show="tableDataes.length === 0"
        >增加
      </el-button>
      <br />
      <span> 每个奖项每次抽取人数： </span>
      <el-select size="small" v-model="batchNumber" @change="onChange">
        <el-option
          v-for="index in 20"
          :key="index"
          :label="index"
          :value="index"
        >
        </el-option>
      </el-select>
      <el-button size="small" type="danger" @click="onReset">
        重置中奖结果
      </el-button>
      <div>
        
          <button style="width: 100px; height: 50px">去抽奖</button>
       
      </div>
    </div>
    <div>
      <button @click="serverMR()">重置(err!)</button>
    </div>
    <div class="members">
      <h1>参与人员列表(共{{ memberSource.length }}人)</h1>
      <el-table :data="memberSource" style="width: 100%" stripe>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          width="180"
          v-for="(item, index) in memberColumns"
          :key="index"
        >
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.editing[item.prop]">
                <el-input
                  size="mini"
                  v-model="scope.row[item.prop]"
                  @blur="onSaveCell(scope.row, item.prop)"
                ></el-input>
              </div>
              <div v-else @dblclick="onEditCell(scope.row, item.prop, $event)">
                {{ scope.row[item.prop] || "(请填写)" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="onRemoveMember(scope.row, scope.$index)"
              type="danger"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button size="small" @click="onAddMember" type="primary">
        增加
      </el-button>
      <el-button size="small" type="warning" @click="onImport">重置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      batchNumber: 20,
      columns: [
        {
          prop: "name",
          label: "分类",
        },
        {
          prop: "count",
          label: "总共人数",
        },
       
      ],
      memberColumns: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "id",
          label: "工号",
        },
      ],
      tableData: [
        {
          name: "张三",
          award: "Air pods",
          count: 3,
        },
        {
          name: "一等奖",
          award: "HUAWEI Mate30Pro",
          count: 2,
        },
        {
          name: "特等奖",
          award: "Apple MacPro",
          count: 1,
        },
      ],
      memberSource: [],
      tableses: [
        {
          prop: "names",
          label: "奖项",
        },
        {
          prop: "number",
          label: "中奖号码",
        },
      ],
      tableDataes: [
        {
          names: "特等奖",
          number: "0",
          editing: {},
        },
        {
          names: "特等奖",
          number: "0",
          editing: {},
        },
        {
          names: "特等奖",
          number: "0",
          editing: {},
        },
      ],
    };
  },
  created: function () {
    var awards = JSON.parse(localStorage.getItem("awards")) || [
      {
        name: "二等奖",
        count: 3,
      },
      {
        name: "一等奖",
        count: 2,
      },
      {
        name: "特等奖",
        count: 1,
      },
    ];
    var members = JSON.parse(localStorage.getItem("members")) || window.members;
      var luckyFellow =   [
        {
          names: "特等奖",
          number: "0",
        },
        {
          names: "特等奖",
          number: "0",
        },
        {
          names: "特等奖",
          number: "0",
        },
      ];
    this.batchNumber = +localStorage.getItem("batchNumber") || 20;
    this.tableData = awards.map(function (item) {
      return {
        name: item.name,
        count: item.count,
        award: item.award,
        editing: {},
      };
    });
    this.memberSource = members.map(function (item) {
      return {
        name: item.name,
        id: item.id,
        editing: {},
      };
    });
    this.tableDataes = luckyFellow.map(function (item) {
      return {
        names: item.names,
        number: item.number,
        editing: {},
      };
    });
    this.saveData();
  },
  computed: {
    awards: function () {
      return this.tableData
        .filter(function (item) {
          return item.name && item.count;
        })
        .map(function (item) {
          return {
            name: item.name,
            count: item.count,
            award: item.award,
          };
        });
    },
    members: function () {
      return this.memberSource
        .filter(function (item) {
          return item.name && item.id;
        })
        .map(function (item) {
          return {
            name: item.name,
            id: item.id,
          };
        });
    },
  },

  methods: {
    onReset: function () {
      this.$confirm("重置会清空所有抽奖结果，无法撤销！", "确定要重置吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        localStorage.removeItem("players");
        localStorage.removeItem("result");
      });
    },
    onChange: function (value) {
      console.log(value);
      localStorage.setItem("batchNumber", value);
    },
    onSaveCell: function (row, key) {
      this.$set(row.editing, key, false);
      this.saveData();
    },
    onEditCell: function (row, key, e) {
      this.$set(row.editing, key, true);
      const parent = e.target.parentNode;
      this.$nextTick(function () {
        // console.log(parent.innerHTML, row)
        parent.querySelector("input").focus();
      });
    },
    onAdd: function (row, index) {
      console.log(row, index);
      this.tableData.splice(index + 1, 0, {
        editing: {
          name: true,
        },
      });
    },
    onRemove: function (row, index) {
      this.tableData.splice(index, 1);
      this.saveData();
    },
    onAdds: function (row, index) {
      console.log(row, index);
      this.tableDataes.splice(index + 1, 0, {
        editing: {
          name: true,
        },
      });
    },
    onRemoves: function (row, index) {
      this.tableDataes.splice(index, 1);
      this.saveData();
    },
    onAddMember: function () {
      this.memberSource.push({
        editing: {
          name: true,
        },
      });
    },
    onRemoveMember: function (row, index) {
      var vm = this;
      this.$confirm("删除不可撤销！可重新添加", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        vm.memberSource.splice(index, 1);
        vm.saveData();
      });
    },
    serverMR: function () {
      localStorage.setItem("awards", JSON.stringify(this.awards));
      localStorage.setItem("members", JSON.stringify(this.members));
      localStorage.setItem("players", JSON.stringify(this.members)); //奖池人员
      localStorage.setItem("luckyFellow", JSON.stringify(this.tableDataes));
      console.log(JSON.parse(localStorage.getItem("luckyFellow"))); //奖品
      console.log("luckyFellow", JSON.stringify(this.tableDataes)); //参赛人员
      localStorage.setItem("batchNumber", JSON.stringify(this.batchNumber));
    },
    onImport: function () {
      var vm = this;
      this.$confirm("重新导入会覆盖现有人员列表", "确定重置吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        vm.memberSource = window.members.map(function (item) {
          return {
            name: item.name,
            id: item.id,
            editing: {},
          };
        });
        vm.saveData();
      });
    },
    saveData: function () {
      localStorage.setItem("awards", JSON.stringify(this.awards));
      localStorage.setItem("members", JSON.stringify(this.members));
      localStorage.setItem("players", JSON.stringify(this.members)); //奖池人员
      localStorage.setItem("luckyFellow", JSON.stringify(this.tableDataes));
      console.log(JSON.parse(localStorage.getItem("luckyFellow"))); //奖品
      console.log("luckyFellow", JSON.stringify(this.tableDataes)); //参赛人员
      localStorage.setItem("batchNumber", JSON.stringify(this.batchNumber));
    },
  },
};
</script>
<style scoped>
.el-input {
  width: 120px;
}

.el-table {
  margin-bottom: 10px;
}

* {
  cursor: initial;
}

.awards,
.members {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

h1 {
  font-size: 30px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 20px;
}

h1 span {
  font-size: 16px;
}

a {
  text-decoration: underline;
  cursor: pointer;
}
</style>